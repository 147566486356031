import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/shared/lib/lazy-dynamic/dynamic-bailout-to-csr.js");
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/Button.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/CImage.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/dynamics/YoutubePlayer.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/error/ComponentError.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/error/ItemError.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/landingPage/customers/CustomersListMobile.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/navigation/Footer/FooterDesktopList.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/navigation/NavBar/DesktopMenu.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/navigation/NavBar/DesktopNavBarPhone.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/navigation/NavBar/LoginButton.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/navigation/NavBar/MobileMenu.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/navigation/NavBar/MobileUserMenu.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/navigation/NavBar/UserDropDownMenu.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/navigation/PhoneBar/PhoneBar.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/prices/Price1Desktop.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/prices/Price1MobileItem.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/prices/Price3Item.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/prices/Price4Item.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/prices/Price5Item.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/slider/ImageSliderMobile.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/ui/dropdown-menu.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/src/utils/events.ts")